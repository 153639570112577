import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

export default defineComponent({
    computed: {
        ...mapGetters({
            hasSubscription: 'subscription/hasSubscription',
            neverHadTrial: 'user/neverHadTrial',
            isWorksheet: 'document/isWorksheet',
            paywallType: 'abtests/paywallType',
            isAdmin: 'user/isAdmin',
            getPremiumFeatures: 'document/getPremiumFeatures',
            isBingo: 'document/isBingo',
            isFlashcard: 'document/isFlashcard',
            userHasPurchasedDocument: 'document/userHasPurchasedDocument',
            isLoggedIn: 'user/isLoggedIn',
        }),
        ...mapState(['user', 'document', 'subscription']),
        showPremiumWall() {
            // If a user has purchased this document, or has a [lifetime] premium subscription
            if (
                this.isAdmin ||
                this.userHasPurchasedDocument ||
                ['Gratis', 'Lifetime', 'Premium', 'Free Trial'].includes(this.user.user.subscription_plan)
            ) {
                return false
            }
            // Creating worksheet with Premium membership
            if (this.subscription.plan?.includes('Premium')) {
                return false
            }
            // Trying to create Worksheet or Bingo or Flashcard with a free membership
            if ((this.isWorksheet && this.neverHadTrial) || (!this.isWorksheet && !this.user.user.has_plan)) {
                return true
            }
            // Trying to create Bingo or Flashcard with a private/worksheets only membership
            if (!this.isWorksheet && (this.hasWorksheetOnlyPlan || !this.subscription.plan)) {
                return true
            }

            // Trying to create Bingo with a 'Flashcard only' basic membership
            if (this.hasFlashcardOnlyPlan && this.isBingo) {
                return true
            }
            // Trying to create Flashcard with a 'bingo only' basic membership
            if (this.hasBingoOnlyPlan && this.isFlashcard) {
                return true
            }
            if (this.getPremiumFeatures.length > 0 && !this.subscription.name) {
                return true
            }
            if (this.getPremiumFeatures.length > 0 && this.isWorksheet && !this.hasWorksheetStandardPlan) {
                return true
            }
            return false
        },
        hasFlashcardOnlyPlan() {
            return this.subscription.plan && this.subscription.plan?.includes('Unlimited Flashcards')
        },
        hasBingoOnlyPlan() {
            return this.subscription.plan && this.subscription.plan?.includes('Unlimited Bingo')
        },
        hasWorksheetStandardPlan() {
            return this.subscription.plan?.includes('Unlimited Worksheets') || this.subscription.plan?.includes('Standard')
        },
        hasWorksheetOnlyPlan() {
            return ['Unlimited Worksheets', 'Private Worksheets', 'Standard', 'Basic', 'Free'].includes(
                this.subscription.plan,
            )
        },
    },
    methods: {
        async showCheckoutModal(forPublish = false) {
            await this.$store.dispatch('document/setOnPublishing', forPublish)
            this.$bvModal.show('checkout-modal')
        },
        async showPrintingPaywall(forPublish = false) {
            if (forPublish) {
                await this.$store.dispatch('document/setOnPublishing', forPublish)
                this.$nextTick(() => {
                    if (this.hasSubscription && forPublish) {
                        this.$bvModal.show('printable-modal')
                    } else if (this.neverHadTrial) {
                        this.$bvModal.hide('printable-modal')
                        this.$bvModal.show('checkout-modal')
                    } else if (this.showPremiumWall) {
                        if (this.getPremiumFeatures.includes('private')) {
                            this.$bvModal.show('printable-modal')
                        } else if (this.paywallType == 'checkout') {
                            this.$bvModal.hide('printable-modal')
                            this.$bvModal.show('checkout-modal')
                        } else if (this.paywallType == 'one_click_upgrade') this.$bvModal.show('printable-modal')
                        else if (forPublish) this.$bvModal.show('printable-modal')
                    } else if (forPublish) {
                        this.$bvModal.show('printable-modal')
                    }
                })
            }
        },
        subscriptionAmount(amount) {
            if (amount.toString().includes('.')) {
                return amount.toString().split('.')[1] === '00'
                    ? amount.toString().split('.')[0]
                    : parseFloat(amount).toFixed(2)
            }
            return amount
        },
        async giveCardlessTrial(plan_id) {
            return new Promise((resolve, reject) => {
                this.$store
                    .dispatch('subscription/cardlessTrial', {
                        plan: plan_id,
                    })
                    .then((response) => {
                        resolve()
                    })
                    .catch((error) => {
                        reject()
                    })
            })
        },
        getPlan(plan) {
            if (!plan) return null

            if (this.subscription_status_loading) return {}

            let brand = this.brand
            if (this.brand?.toLowerCase() == 'flashcard') brand = 'flashcards'
            if (this.brand?.toLowerCase() == 'account') brand = 'worksheet'

            if (['basic', 'standard'].includes(plan.toLowerCase()))
                return this.subscription.plans[plan][brand][this.subscription.one_click_content.plan_frequency]
            if (plan.toLowerCase() == 'premium')
                return this.subscription.plans[plan][this.subscription.one_click_content.plan_frequency]

            return this.subscription.plans[plan]
        },
        showPremiumPaywall() {
            //close all popovers
            this.$root.$emit('bv::hide::popover')

            if (this.hasSubscription) {
                this.$bvModal.show('paywall-modal')
            } else if (this.neverHadTrial && this.showPremiumWall && this.isLoggedIn) {
                this.$bvModal.show('checkout-modal')
            } else {
                if (this.isLoggedIn) {
                    if (this.paywallType == 'checkout') this.$bvModal.show('checkout-modal')
                    else if (this.paywallType == 'one_click_upgrade') this.$bvModal.show('paywall-modal')
                    else this.$bvModal.show('paywall-modal')
                } else {
                    this.$bvModal.show('login-modal')
                }
            }
        },
        decimalValue(value) {
            return parseFloat(value).toFixed(1)
        },
        generateRandomString(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            let result = ''
            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length)
                result += characters.charAt(randomIndex)
            }
            return result
        },
        newTab(url, focus = false, filename = null) {
            const a = document.createElement('a')
            a.href = url
            a.target = '_blank'
            if (filename) a.download = filename
            const e = new MouseEvent('click', {
                ctrlKey: focus,
                metaKey: focus,
            })
            a.dispatchEvent(e)
        },
    },
})
